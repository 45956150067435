/*데스크탑 브라우저 가로*/
@media ( min-width: 1024px ) {
    .posting-btn{
        left: calc(50% + 250px);
    }
}

/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1024px) {
    .root {
        max-width: none; 
    }
    .gnb {
        max-width: none;
    }
    .mobile-header{
        max-width: none;
    }
    .prodcut-content{
        max-width: none;
        padding: 0 20px;
    }
    .reply-wrap{
        max-width: none;
    }
    .posting-btn{
        right: 10px;
    }
    .stq-bottom{
        max-width: none;
    }
}


/*모바일(스마트폰)*/
@media (max-width : 768px)
{
    .root {
        min-height: 100vh; 
        box-sizing: border-box; 
    }
    
    .inner{
        padding: 16px;
    }
    .product-wrap .product-item .thumbnail{
        height: 200px;
    }
    .product-wrap .product-item .product-info .vendor{
        font-size: 12px;
    }
    .product-wrap .product-item .product-info .pd-salePrice{
        font-size: 14px;
    }
    .product-wrap .product-item .product-info .pd-name{
        font-size: 12px;
    }
    .post-wrap .post-content .post-tit{
        font-size: 14px;
    }
    .post-wrap .post-content .post-desc{
        font-size: 13px;
    }
    .post-tag {
        font-size: 11px;
        padding: 4px 6px;
    }

    .posting-btn{
        right: 10px;
    }

    .post-title{
        font-size: 14px;
    }
    .post-details-info .post-user span{
        font-size: 13px;
    }
    .post-details-info .post-user img{
        width: 21px;
        height: 21px;
        margin-right: 0.2rem;
    }
    .post-details-info .post-user{
        margin-top: 0px;
    }
    .post-details-info .post-date span{
        font-size: 12px;
    }
    .post-content span{
        font-size: 14px;
    }
    .post-comment .comment-wrap .comment-content .comment-info .nickname{
        font-size: 13px;
    }
    .post-comment .comment-wrap .comment-content span{
        font-size : 13px;
    }
    .offcanvas.offcanvas-bottom {
        height: 50vh !important;
    }
    .post-img-filebox label, .post-img-filebox img{
        width: 80px;
        height: 80px;
    }
}


/* 576px 이하일 때 */
@media (max-width: 576px) {
    .product-wrap .product-item .thumbnail{
        height: 200px;
    }
    .product-wrap .product-item .product-info .vendor{
        font-size: 12px;
    }
    .product-wrap .product-item .product-info .pd-salePrice{
        font-size: 14px;
    }
    .product-wrap .product-item .product-info .pd-name{
        font-size: 12px;
    }
    .post-wrap .post-content .post-tit{
        font-size: 14px;
    }
    .post-wrap .post-content .post-desc{
        font-size: 13px;
    }
    .post-tag {
        font-size: 11px;
        padding: 4px 6px;
    }
    .posting-btn{
        right: 10px;
    }

    .post-title{
        font-size: 14px;
    }
    .post-details-info .post-user span{
        font-size: 13px;
    }
    .post-details-info .post-user img{
        width: 21px;
        height: 21px;
        margin-right: 0.2rem;
    }
    .post-details-info .post-user{
        margin-top: 0px;
    }
    .post-details-info .post-date span{
        font-size: 12px;
    }
    .post-content span{
        font-size: 14px;
    }
    .post-comment .comment-wrap .comment-content .comment-info .nickname{
        font-size: 13px;
    }
    .post-comment .comment-wrap .comment-content span{
        font-size : 13px;
    }
    .offcanvas.offcanvas-bottom {
        height: 50vh !important;
    }
    .post-img-filebox label, .post-img-filebox img{
        width: 80px;
        height: 80px;
    }
}

@media (max-width: 360px) {
    .m-category-icon{
        width: 20px;
        height: 20px;
    }
}