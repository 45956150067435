* {
    box-sizing: border-box;
    outline: none;
}
a {
    text-decoration: none;
    color: inherit;
}
h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    font-weight: 500;
}

/* Heading Font Size */
h1 {font-size: 28px;}
h2 {font-size: 22px;}
h3 {font-size: 18px;}
p {margin: 0 !important; padding: 0 !important;}

input,
button,
select {
    outline: none;
}
ul{list-style: none;}

html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, img {
margin: 0;
padding: 0;
}

table {
    width: 100%;
    border: 0;
    border-spacing: 0;
    border-collapse: collapse;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover{
    color : none !important;
}

input::placeholder {
    color: var(--bs-gray-600);
    font-weight: 500;
}

select option {
    font-weight: 500;
}
label{
    font-weight: 500; 
}
input[type="text"],
input[type="password"] {
    font-weight: 500;
}
button {
    width: auto;
    border: 0;
    padding: 0;
    background: none;
    font-weight: normal;
}
span{
    font-weight: 500;
}

a {
    color: inherit !important;
    text-decoration: none !important; 
}
  
.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}
dl, ol, ul{
    margin: 0 !important;
    padding: 0 !important;
}
.table td, .table th {
    max-width: 120px; /* 텍스트의 최대 길이를 제한합니다. */
    overflow: hidden;
    text-overflow: ellipsis; /* 텍스트가 너무 길면 ...으로 생략되도록 설정 */
}

/* 공통 */
.error_message{
    color: red;
}
.error-input {
    border: 1px solid red !important;
}
.error-input2 {
    border-bottom: 1px solid red !important;
}
.error-message{
    color: red;
}
.mt-3{
    margin-top: 10px;
}
.mt-5{
    margin-top: 50px;
}
.btn_2{
    height: 50px;
    width: 100%;
    background: var(--m-main-color);
    border:none;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    box-sizing: border-box !important;
    border-radius: 4px;
}
.btn_white_2{
    background-color: #fff;
    color: #000;
    border: 1px solid;
    font-weight: bold;
    font-size: 15px;
    width: 148px;
    height: 50px;
}

.btn_black_1{
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    width: 100%;
    height: 50px;
}
.btn_black_2{
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    width: 148px;
    height: 50px;
}
.btn_disable_2{
    background-color: #a2a2a2;
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    width: 148px;
    height: 50px;
}

.pointer{
    cursor: pointer;
}

.btn-primary {
    font-size: 17px;
    border : none;
    border-radius: 5px;
    color : #fff;
    width: 100%;
    padding: 12px;
    font-weight: 500;
}
.btn-primary.findBtn{
    background-color: var(--main-color);
}

/* [hm] 폰트 컬러 설정 */
.fc-main {
    color: #E85F43;
}
.fc-sub {
    color: #FF9680;
}
.fc-light {
    color: #FFCCC2;
}
.fc-gray {
    color: #666666;
}
.bg-main {
    background-color: #E85F43;
}