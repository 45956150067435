/* Font Family CDN */
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');
/* 
@font-face {
    font-family: 'SUIT-Regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
} */

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/SUIT-Thin.otf') format('opentype'),
        url('../fonts/SUIT-Thin.woff2') format('woff2');
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/SUIT-ExtraLight.otf') format('opentype'),
        url('../fonts/SUIT-ExtraLight.woff2') format('woff2');
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/SUIT-Light.otf') format('opentype'),
        url('../fonts/SUIT-Light.woff2') format('woff2');
    ;
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/SUIT-Regular.otf') format('opentype'),
        url('../fonts/SUIT-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/SUIT-Medium.otf') format('opentype'),
         url('../fonts/SUIT-Medium.woff2') format('woff2');
    ;
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/SUIT-SemiBold.otf') format('opentype'),
        url('../fonts/SUIT-SemiBold.woff2') format('woff2');
    ;
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/SUIT-Bold.otf') format('opentype'),
        url('../fonts/SUIT-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/SUIT-ExtraBold.otf') format('opentype'),
        url('../fonts/SUIT-ExtraBold.woff2') format('woff2')
    ;
}

@font-face {
    font-family: 'SUIT';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/SUIT-Heavy.otf') format('opentype'),
        url('../fonts/SUIT-Heavy.woff2') format('woff2');
    ;
}


:root {
    /* Font Family */
    --primary-kr: 'SUIT', sans-serif;
    --primary-en: 'SUIT', sans-serif;

    /* Object Color */
    --main-color : #e85f43; 
    --m-main-color : #ff843d;
}