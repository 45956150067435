* {
	box-sizing: border-box;
	font-family: var(--primary-kr);
	font-weight: 500;
}

body {
	font-size: 15px;
	line-height: 1.5em;
	font-weight: 500;
	color: #000;
	font-family: var(--primary-kr);
	margin: 0;
}

/* Layout CSS */
.container {
	width: 100%;
	position: relative;
	/* border: 1px solid #000; */
	/* max-width: 1200px;  */
	/* padding: 0; */
}

.mobile-header {
	display: block;
	position: fixed;
	background-color: #fff;
	width: 100%;
	max-width: 600px;
	top: -1px;
	z-index: 99;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.mobile-header .text-center {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

#container {
	/* max-width: 600px;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    margin: 0 auto;
    border: 1px solid; */
}

.root {
	max-width: 600px;
	width: 100%;
	height: 100vh;
	background: #ffffff;
	margin: 0 auto;
	/* border: 1px solid; */
	position: relative;
	/* overflow: hidden; */
}

.inner {
	padding: 10px;
}

/* GNB CSS */
.gnb {
	border-top: 1px solid #eeeeee;
	/* border: 1px solid #000; */
	max-width: 600px;
	margin: auto;
	height: 64px;
	display: flex;
	justify-content: space-between;
	position: fixed;
	bottom: -1px;
	width: 100%;
	background-color: #fff;
	z-index: 99;
	border-radius: 16px 16px 0px 0px;
	border-top: 0.5px solid #d2d5d6;
	border-left: 0.5px solid #d2d5d6;
	border-right: 0.5px solid #d2d5d6;
}
.gnb a {
	text-align: center;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: #878787 !important
}
.gnb a .svg-inline--fa {
	font-size: 20px;
	margin-bottom: 2px;
}
.gnb a span {
	display: block;
	font-size: 12px;
}
.main {
	overflow-x: hidden;
}

.gnb a svg {
	fill: #878787 !important;
	width: 24px;
	height: 24px;
	object-fit: contain;
}
.main .gnb a:nth-child(1),
.bbtitest .gnb a:nth-child(2),
.magazine .gnb a:nth-child(3),
.post .gnb a:nth-child(4),
.mypage .gnb a:nth-child(5),
.mypagev2 .gnb a:nth-child(5),
.bbtitest-result .gnb a:nth-child(5){
	color: var(--main-color) !important;
}
.main .gnb a:nth-child(1) svg,
.bbtitest .gnb a:nth-child(2) svg,
.magazine .gnb a:nth-child(3) svg,
.post .gnb a:nth-child(4) svg,
.mypage .gnb a:nth-child(5) svg,
.mypagev2 .gnb a:nth-child(5) svg,
.bbtitest-result .gnb a:nth-child(5) svg
{
	fill: var(--main-color) !important;
}
/* .bbtitest,
.mypage,
.magazine{
    height: 100vh;
} */
 .mypagev2{
	background-color : #f7f3e4;
	overflow-x: hidden; /* [hm] 화면 횡스크롤 차단 */
 }
.app-banner {
	background: linear-gradient(to right, #ff6847, #ff9f47);
	width: 100%;
	padding: 12px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.app-banner div {
	display: flex;
	align-items: center;
}
.app-banner div span {
	color: #ffffff;
	font-weight: 600;
	font-size: 14px;
}
.app-banner div img {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	border-radius: 4px;
}

.app-banner .app-banner-text {
	font-weight: bold;
	display: inline-block;
	padding: 5px 12px;
	background-color: #ffffff;
	color: #000000;
	border-radius: 100px;
	font-size: 12px;
}

.banner-content {
}
.banner-content video[poster] {
	width: 100%;
	height: 100%;
}
.magazine-content {
}
.main-content {
	margin-bottom: 2rem;
}
.recommended-content {
	margin-bottom: 2rem;
}
.magazine-main {
	position: relative;
	margin-bottom: 20px;
}
.magazine-main .thumbnail {
	width: 100%;
	height: 0;
	padding-top: 100%;
	overflow: hidden;
	position: relative;
}
.magazine-main .thumbnail img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 0.2s linear;
}
.magazine-main .thumbnail img:hover {
	transform: scale(1.1);
}
.magazine-main .thumbnail-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.5)
	);
}
.magazine-main .magazine-main-info {
	position: absolute;
	bottom: 30px;
	left: 19px;
	color: #fff;
	font-weight: bold;
	padding-right: 20px;
}

.magazine-main .magazine-main-info .infoBox {
	display: flex;
	margin-bottom: 16px;
}

.magazine-main .magazine-main-info .infoBox span:after {
	content: "|";
	display: inline-block;
	font-size: 12px;
	color: #ffffff;
	margin-right: 5px;
	padding-left: 7px;
	transform: translateY(-1px);
}

.magazine-main .magazine-main-info .infoBox span:last-child:after {
	display: none;
}
.magazine-main .magazine-main-info .mainCTitle {
	font-size: 20px;
	font-weight: 600;
	letter-spacing: -1px;
}
.title-wrap {
	display: flex;
	justify-content: space-between;
	margin: 30px 0px 30px 0px;
	align-items: end;
}

.main-title {
	color: rgb(32, 32, 32);
	font-size: 22px;
	line-height: 1.2;
	letter-spacing: -0.5px;
	font-weight: 500;
}
.morebtn {
	color: #a2a2a2;
	font-size: 12px;
	cursor: pointer;
}
.magazine-wrap {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}
.magazine-item {
	width: calc(50% - 4px);
	/* border: 1px solid #000; */
	display: flex;
	flex-direction: column;
}
.magazine-item .magazineCard .thumbnail img {
	width: 100%;
	/* height: 100%; */
	height: calc(34.51vw);
	max-height: 190px;
	object-fit: cover;
}

.magazine-item .magazineCard .cardText {
}
.magazine-item .magazineCard .cardText .info p {
	font-size: 14px;
	line-height: 20px;
	color: #000;
	font-weight: 500;
}

.magazine-item .magazineCard .cardText .tagBox {
	/* border: 1px solid #000; */
	/* border: 1px solid rgb(238, 238, 238); */
	color: rgb(32, 32, 32);
	font-size: 13px;
	margin-bottom: 5px;
	/* padding: 1px 5px; */
	font-weight: 400;
	margin-top: 10px;
	display: inline-block;
}

.magazine-item .magazineCard .cardFooter span {
	color: #a2a2a2;
	font-size: 12px;
	font-weight: 400;
}

.magazine-item .magazineCard .cardFooter span:after {
	content: "|";
	display: inline-block;
	font-size: 12px;
	color: #bbb;
	margin-right: 5px;
	padding-left: 7px;
	transform: translateY(-1px);
}

.magazine-item .magazineCard .cardFooter span:last-child:after {
	display: none;
}

/* 메인페이지 > 최신 글 */
.latestContent-wrap {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.latest-item {
	/* border: 1px solid #000; */
	display: flex;
	/* align-items: center; */
	gap: 16px;
	cursor: pointer;
}
.latest-item .thumbnail {
	flex: 1;
}
.latest-item .thumbnail img {
	width: 100%;
	height: calc(34.51vw);
	max-height: 160px;
	object-fit: cover;
}

.latest-item .cardText .info p {
	font-size: 14px;
	line-height: 20px;
	color: #000;
	font-weight: 500;
}
.latest-item .cardText {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: start;
}

.latest-item .cardText .tagBox {
	/* border: 1px solid #000; */
	/* border: 1px solid rgb(238, 238, 238); */
	color: rgb(32, 32, 32);
	font-size: 13px;
	margin-bottom: 5px;
	/* padding: 1px 5px; */
	font-weight: 400;
	display: inline-block;
}

.latest-item .cardFooter span {
	color: #a2a2a2;
	font-size: 12px;
}

.latest-item .cardFooter span:after {
	content: "|";
	display: inline-block;
	font-size: 10px;
	color: #bbb;
	margin-right: 5px;
	padding-left: 7px;
	transform: translateY(-1px);
}

.latest-item .cardFooter span:last-child:after {
	display: none;
}

/* 2024.05.27 : [CHM] : 레이아웃 우측 여백 제거를 위해 수정됨 */
.product-wrap {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
}
.goods-badge {
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	position: absolute;
	bottom: 12px;
	left: 4px;
}
.badge {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: normal;
	border-radius: 10px !important;
	padding: 4px 9px !important;
	color: #fff;
}

.badge.hot {
	background-color: #ff4789;
}
.badge.bbti {
	background-color: #9747ff;
}

/* 2024.05.27 : [CHM] : 레이아웃 우측 여백 제거를 위해 수정됨 */
.product-wrap .product-item {
	cursor: pointer;
	box-sizing: border-box;
}

.product-wrap .product-item .product-info {
	margin-top: 10px;
	overflow-wrap: anywhere;
}

.product-wrap .product-item .thumbnail {
	width: 100%;
	height: 300px;
	position: relative;
}
.product-wrap .product-item .thumbnail img {
	width: inherit;
	height: inherit;
	object-fit: cover;
}

.product-wrap .product-item .product-info .vendor {
	font-weight: 700;
	font-size: 14px;
	margin: 0;
	margin-top: 10px;
}
.product-wrap .product-item .product-info .pd-name {
	font-size: 13px;
	line-height: 1.2em;
	font-weight: normal;
	margin-bottom: 5px;
	overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal
}

.product-wrap .product-item .product-info .pd-discount span {
	/* -webkit-text-decoration-line: line-through; */
	/* text-decoration-line: line-through; */
	/* color: #a2a2a2; */
	margin-left: 4px;
	font-size: 14px;
	font-weight: 600;
}

.product-wrap .product-item .product-info .pd-discount b {
	color: var(--main-color);
	font-size: 14px;
	text-decoration-line: none;
}
.product-wrap .product-item .product-info .pd-salePrice {
	font-weight: 500;
	font-size: 17px;
	color: rgb(0, 0, 0);
	word-break: break-word;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Admin Login Page */
#Adminlogin {
	background-color: #ff843d21;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	flex-direction: column;
}
.Adminlogin {
	width: 500px;
	min-width: inherit;
	max-width: 450px;
	background-color: #fff;
	box-shadow: 0 25px 40px rgba(0, 0, 0, 0.1);
	padding: 35px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 48px 0px 12px 0px;
	/* border : 1px solid rgba(0, 0, 0, 0.25); */
}

.loginspan {
	font-size: 14px;
	font-weight: 500;
}
.adminLogo-wrap {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 20px;
}
.adminLogo-wrap img {
	max-width: 100px;
	margin-bottom: 1rem;
}
.adminLogo-wrap p {
	font-weight: 500;
}
/* ###### Login page ######## */
.login-content {
	margin-top: 8rem;
}
.login-content h1 {
	margin-bottom: 40px;
	font-size: 30px;
	line-height: 30px;
	color: #111111;
	text-align: center;
	border-bottom: 2px solid #000000;
	padding-bottom: 30px;
	font-weight: bold;
}
.signIn {
	width: 500px;
	max-width: 500px;
	margin: 0 auto;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.25);
	background-color: #fff;
	margin: 0 auto;
	padding: 24px;
}
.signIn h2 {
	margin-bottom: 40px;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	border-bottom: 1px solid var(--m-main-color);
	padding-bottom: 30px;
	font-weight: 500;
	color: var(--m-main-color);
}
.signtitbox {
	margin-bottom: 30px;
}
.signtitbox h2 {
	color: var(--m-main-color);
	font-size: 30px;
	line-height: 30px;
	text-align: center;
	font-weight: 500;
}
.signtitbox .subtit {
	color: #667085;
	font-weight: 500;
	letter-spacing: -0.69px;
}
.signIn-form {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.icheck_box {
	display: flex;
	align-items: center;
	gap: 5px;
}
.icheck_box div {
	flex: 3;
}
.icheck_box button {
	flex: 1;
	border: 1px solid;
	padding: 10px 0;
}
.signIn-form .input_box {
	/* border-bottom: 1px solid #000000; */
}
.signIn-form .input_box.btbox {
	border-top: 1px solid #000000;
	padding-top: 30px;
}
.signIn-form .input_box p {
	color: #666;
	padding-bottom: 0.3rem;
	font-weight: 600;
	text-align: left;
	margin: 0;
	font-size: 14px;
}
/*
.signIn-form .input_box input[type=password],
.signIn-form .input_box input[type=text]{
    width: 100%;
    padding-left: 20px;
    background: #ffffff;
    height: 44px;
    box-sizing: border-box;
    border : 1px solid #ddd;
    transition: 1s;
    margin-bottom: 20px;
}
*/
.signIn-form .input_box input {
	width: 100%;
	padding-left: 20px;
	/* background: #ffffff; */
	height: 44px;
	box-sizing: border-box;
	border: 1px solid #ddd;
	transition: 1s;
	margin-bottom: 20px;
}
.signIn-form .input_box input[type="checkbox"] {
	width: 14px;
	height: 15px;
	padding: 0;
}
.signIn-form .input_box input::placeholder {
	font-size: 13px;
	color: #333;
}

.signIn-form .input_box input:focus {
	border: 1px solid #000;
}

.signIn-form .input_box input[type="file"] {
	margin: 10px 0 20px 0;
}
.signIn-form select {
	border: 1px solid #ddd;
	height: 44px;
	width: 100%;
	margin-bottom: 20px;
	padding: 0 10px;
}

.input-form select {
	border: 1px solid #ddd;
	height: 44px;
	width: 100%;
	margin-bottom: 20px;
	padding: 0 10px;
}

.input-form .input_box input[type="text"] {
	width: 100%;
	padding-left: 20px;
	/* background: #ffffff; */
	height: 44px;
	box-sizing: border-box;
	border: 1px solid #ddd;
	transition: 1s;
	margin-bottom: 20px;
}
.input-form .input_box input[type="checkbox"] {
	width: 14px;
	height: 15px;
	padding: 0;
}
.input-form .input_box input::placeholder {
	font-size: 13px;
	color: #333;
}

.input-form .input_box input:focus {
	border: 1px solid #000;
}

.login_check {
	position: relative;
	min-height: 20px;
	margin-bottom: 40px;
}
.login_check li:not(:last-child):before {
	content: "";
	position: absolute;
	top: 5px;
	right: 0;
	width: 1px;
	bottom: 5px;
	background: #cccccc;
}
.login_check ul {
	position: absolute;
	top: 0;
	right: 0;
}
.login_check li {
	float: left;
	padding: 0px 11px 0px 10px;
	position: relative;
	line-height: 20px;
	font-size: 15px;
}
.login_check li:last-child {
	padding-right: 0;
}
.login_check li a {
	font-weight: bold;
	font-size: 14px;
}

/* ########### Admin Page Css ############ */
.m-header {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	min-width: 1080px;
	background: #ffffff;
	/* height: 75px; */
	padding: 0;
	transition:
		background-color 0.3s,
		height 0.3s;
	border-bottom: 1px solid var(--bs-border-color);
}
.m-header .logo {
	padding: 20px;
	text-align: center;
}
.m-header .status-bar {
	/* background-color: #fcfafa;*/
	padding: 20px;
	display: flex;
}
.m-header .status-bar .tit-wrap {
	display: flex;
	flex-direction: column;
}

.m-header .status-bar .tit-wrap span:nth-child(1) {
	font-size: 17px;
	font-weight: 500;
	letter-spacing: -1px;
}
.m-header .status-bar .tit-wrap span:nth-child(2) {
	font-size: 15px;
	letter-spacing: -1px;
	font-weight: 400;
}

.m-header .status-bar .setting-wrap {
}
.admin-header-wrap {
	display: flex;
	align-items: center;
}
.admin-logo-box {
	width: 13%;
}
.admin-logout-box {
	margin-left: auto;
	padding-right: 41px;
}
.m-nav {
	margin-top: 91px;
	background-color: var(--main-blue);
	padding: 20px;
	/* width: 13%; */
	position: fixed;
	overflow-y: auto;
	height: 100vh;
	float: left;
	z-index: 99;
	box-shadow: transparent 0px 0px 0px inset;
	border-right: 1px solid var(--bs-border-color);
}
.m-nav .menu {
	display: flex;
	gap: 10px;
	padding: 10px;
	align-items: center;
	background-color: #000;
	border-radius: 5px;
	margin-bottom: 10px;
	color: #fff;
}
.m-nav .menu span {
	letter-spacing: -0.5px;
	font-size: 15px;
	font-weight: bold;
}
.m-nav .active.menu {
	background-color: var(--m-main-color);
}

.m-nav2 {
	margin-top: 91px;
	background-color: var(--main-blue);
	padding: 20px;
	width: 13%;
	position: fixed;
	overflow-y: auto;
	height: 100vh;
	float: left;
	z-index: 99;
	box-shadow: transparent 0px 0px 0px inset;
	border-top: 1px solid var(--bs-border-color);
	border-right: 1px solid var(--bs-border-color);
}
.m-nav2 .menu {
	display: flex;
	gap: 10px;
	padding: 15px 10px;
	align-items: center;
	background-color: #fff;
	color: #555555;
	/*color: #767676;*/
}
.m-nav2 .menu span {
	letter-spacing: -0.5px;
	font-size: 15px;
	font-weight: bold;
}
.m-nav2 .active.menu {
	background-color: #ff47898a;
	color: var(--m-main-color);
}

.Mnav {
	margin-top: 87px;
	background-color: var(--main-blue);
	/*   padding: 20px;*/
	padding-top: 10px;
	width: 13%;
	/* width: 260px; */
	position: fixed;
	overflow-y: auto;
	height: 100vh;
	float: left;
	z-index: 99;
	box-shadow: transparent 0px 0px 0px inset;
	/*border-top: 1px solid var(--bs-border-color);*/
	border-right: 1px solid var(--bs-border-color);
}
.category-wrap {
	padding: 10px 20px;
	border: 1px solid var(--bs-border-color);
	border-radius: 5px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
}
.category-wrap div {
	display: flex;
	justify-content: space-between;
	align-content: center;
}
.category-wrap.active {
	background-color: var(--m-main-color);
}

.category-wrap.active h2 {
	color: #ffffff;
}

.category-wrap h2 {
	cursor: pointer;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -1px;
	color: #000;
	margin: 0;
}
.category-wrap a {
	letter-spacing: -0.5px;
	font-size: 15px;
	font-weight: 400;
}
.category-wrap ul {
	margin-left: 8px;
	margin-bottom: 24px;
}
.category-wrap ul li {
	margin-bottom: 10px;
	margin-top: 16px;
}
.category-wrap ul li.active {
	color: var(--m-main-color);
}
.admin-content {
	width: 87%;
	margin-top: 87px;
	float: right;
	/* background-color: #f9fafb; */
	height: auto;
	min-height: 100%;
	/* padding-left: 30px; 
    padding-top: 10px; */
	border-top: 1px solid var(--bs-border-color);
	padding: 13px 20px 20px 20px;
}
.statistics-content {
	margin-bottom: 2rem;
}
.group-title {
	color: #333;
	font-weight: 500;
	font-size: 17px;
	letter-spacing: -0.5px;
}

.statistics-wrap {
	display: flex;
	gap: 10px;
}
.box {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 4px;
	box-shadow:
		0 2px 3px rgba(0, 10, 18, 0.1),
		0 0 0 1px rgba(0, 10, 18, 0.1);
}
.box.h-272 {
	height: 272px;
}

.box.ranking {
	height: inherit;
	margin-top: 30px;
}
.box span {
	letter-spacing: -0.5px;
}

.box .tit {
	position: relative;
	padding: 10px 0 20px 0;
	border-bottom: 1px solid #eeeeee;
}
.box .tit .icon {
	margin-right: 10px;
	color: var(--main-color);
}
.box .tit a,
.box .tit button {
	position: absolute;
	right: 0;
	background: #000;
	font-weight: bold;
	letter-spacing: -0.5px;
	color: #fff;
	padding: 10px 30px;
	border-radius: 5px;
	top: -4px;
}

.box .tit .selectBtn {
	position: static;
	background: #fff;
	font-weight: 400;
	letter-spacing: -0.5px;
	color: #000 !important;
	padding: 0;
	margin-right: 10px;
}
.selectDropdown li a {
	position: static !important;
	background: #fff !important;
	color: #000 !important;
	padding: 5px !important;
	cursor: pointer;
	font-size: 15px;
	font-weight: 500 !important;
}
.box .input-box {
	padding: 20px 0;
	display: flex;
	gap: 20px;
	align-items: center;
}
.box .input-box input {
	width: 10%;
}
.box .input-box span {
	width: 6%;
	font-weight: bold;
	font-size: 17px;
	letter-spacing: -0.5px;
}

.a-dash-table {
	/* margin-top: 25px; */
}
.a-dash-table thead th,
.a-dash-table tbody td {
	padding: 15px 0;
}
.a-dash-table thead th {
	border-bottom: 1px solid #b3b0b0;
	font-weight: 500;
}
.a-dash-table tbody tr:hover {
	background-color: rgba(235, 246, 255, 0.5);
}
.a-dash-table .tcenter {
	text-align: center;
}
.side-menu {
}

.admin_table {
	text-align: center;
}
.admin_table thead {
	/* border-bottom: 1px solid #000; */
	border-top: 1px solid #e2e2e5;
	border-bottom: 1px solid #e2e2e5;
	background: #f2f4f5;
	color: #5c5c5c;
	font-size: 16px;
	font-weight: 700;
}
.admin_table thead th {
	font-weight: 500;
	padding: 15px 0;
	font-size: 14px;
	letter-spacing: -0.5px;
}

.admin_table tbody {
}
.admin_table tbody tr {
	border-bottom: 1px solid #e2e2e5;
}
.admin_table tbody tr:hover {
	background-color: rgba(235, 246, 255, 0.5);
}

.admin_table tbody tr td {
	padding: 15px 0;
	font-weight: 400;
}
.admin_table tbody li {
	font-weight: 400;
}
.admin_table tbody span {
	font-weight: 400;
}
.admin_table img {
	width: 100px;
	min-width: 100px;
}

.search-wrap {
	display: flex;
	gap: 10px;
}

.search-wrap select {
	width: 10%;
	border: 1px solid #ddd;
	border-radius: 5px;
	padding-left: 20px;
	font-weight: 400;
}
.search-wrap select:hover {
	/* box-shadow: 0 0 5px var(--main-color); */
	border: 1px solid var(--m-main-color);
}

.search-wrap .search-field {
	position: relative;
	width: 90%;
}
.search-wrap .search-field i {
	position: absolute;
	color: #b5b5b5;
	left: 13px;
	bottom: 17px;
	font-size: 26px;
}

.search-wrap .search-field input[type="text"] {
	width: 100%;
	padding: 15px 0 15px 50px;
	box-sizing: border-box;
	border: 1px solid #ddd;
	border-radius: 5px;
	transition: 0.3s;
}
.search-wrap .search-field input[type="text"]:focus {
	/* box-shadow: 0 0 5px var(--main-color); */
	border: 1px solid var(--m-main-color);
}

.table-striped {
}
.table-striped {
	text-align: center;
}
.table-striped th {
	font-weight: bold;
	padding: 15px 0;
}

.menu-2 {
	margin-bottom: 2rem;
}
.menu-2 .side-menu {
	display: flex;
	gap: 7px;
	flex-wrap: wrap;
}

.menu-2 .side-menu .side-item {
	width: 200px;
}

.side-menu {
}
.side-menu .side-item {
	padding: 10px;
	border: 1px solid #979797;
	border-radius: 5px;
	letter-spacing: -0.5px;
	font-size: 15px;
	font-weight: 400;
	text-align: center;
	margin-bottom: 10px;
	cursor: pointer;
}
.side-menu .side-item.active {
	background-color: var(--m-main-color);
	color: #fff;
	border: 0;
}
.side-menu .side-item.active a {
	font-weight: bold;
}

/* Writing.js CSS */
.tit_area {
	font-size: 18px;
	font-weight: bold;
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	padding: 0;
	align-items: flex-end;
}

.tit_area.line_thick {
	margin-bottom: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid #eee;
}
.tit_area .tit_lv2 {
	font-size: 20px;
	font-weight: 500;
	line-height: 1.29;
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	word-break: break-all;
}
.form_table {
	display: table;
	width: 100%;
	border-collapse: collapse;
	border-top: 1px solid #000;
}
.form_table .tr .th,
.form_table .tr .td {
	display: table-cell;
	padding: 20px 0;
	vertical-align: top;
}

.form_table .tr {
	display: table-row;
	display: flex;
	border-bottom: 1px solid #f2f2f2;
}
.form_table .tr .th {
	width: 160px;
}
.form_table .tr .td {
	flex: 1;
	margin: 0;
	margin-left: 30px;
}
.form_table .tr .td select,
.form_table .tr .td input[type="text"] {
	width: 100%;
	height: 48px;
	padding: 0 15px;
	font-size: 14px;
	color: #333;
	border: 1px solid #cccccc;
	background: #ffffff;
	border-radius: 3px;
	transition: all 0.5s;
	vertical-align: middle;
	font-weight: bold;
}
.form_table .tr .td input[type="text"]:hover {
	border: 1px solid #000;
}
.form_table.no_line {
	margin-top: 40px;
	border-top: none;
}
.form_table .form_label {
	height: 48px;
	margin-top: 0;
	display: flex;
	align-items: center;
	font-weight: 500;
}

.form_table .form_label {
	height: 48px;
	margin-top: 0;
	display: flex;
	align-items: center;
}
.form_label.required::after {
	content: "*";
	display: inline-block;
	margin-left: 4px;
	color: #000;
	font-weight: 700;
}
.form_label {
	margin-top: 20px;
	font-size: 15px;
	font-weight: 700;
}

/* Spinner.css */
.loading-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 4px solid #f3f3f3;
	border-top: 4px solid var(--main-color);
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.parent-container {
	display: flex;
	justify-content: center;
}
.toggle-container {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.toggle-label {
	margin-right: 20px;
	cursor: pointer;
	position: relative;
}

.toggle-label input[type="radio"] {
	display: none; /* 동그란 표시를 가림 */
}

.toggle-button {
	display: inline-block;
	padding: 10px 20px;
	font-weight: bold;
	cursor: pointer;
}

.toggle-label input[type="radio"]:checked + .toggle-button {
	background-color: var(--main-color);
	color: #fff;
}

.filebox {
	display: flex;
	gap: 10px;
	margin-bottom: 20px;
}

.filebox .upload-name {
	display: inline-block;
	height: 40px;
	padding: 0 10px;
	vertical-align: middle;
	border: 1px solid #dddddd;
	width: 78%;
	color: #000;
}
.filebox label {
	display: inline-block;
	padding: 10px 18px;
	color: #fff;
	vertical-align: middle;
	background-color: var(--m-main-color);
	cursor: pointer;
	height: 40px;
	font-weight: bold;
	font-size: 15px;
}

/* 
#productImage_file {
    display: none;
} */
.filebox input[type="file"] {
	position: absolute;
	width: 0;
	height: 0;
	padding: 0;
	overflow: hidden;
	border: 0;
}

.modal-title {
	font-weight: 500 !important;
	letter-spacing: -0.8px !important;
	font-size: 18px !important;
}

/* 페이지 내비게이션 버튼 */
.pagination-wrap {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px 0;
}
.m-page-nav-btn {
	display: flex;
	justify-content: center;
	/* gap: 8px; */
}
.m-page-nav-btn li {
	background-color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
	margin-right: 4px;
	cursor: pointer;
	font-weight: 500;
}
.m-page-nav-btn li:hover {
	background-color: #e1e6ec;
}
.m-page-nav-btn .cur {
	background-color: var(--m-main-color);
	color: #fff;
}
.m-page-nav-btn .cur:hover {
	background-color: var(--main-color);
}
.m-page-nav-btn button {
	background: none;
	/* border: 1px solid var(--main-color); */
	border: none;
	color: var(--main-color);
	border-radius: 5px;
}

.btn-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	margin-top: 2rem;
}
.sub-tit {
	letter-spacing: -0.5px;
	padding: 15px 0;
	font-weight: 500;
}
.bar_ratio {
	width: 100%;
	height: 10px;
	background-color: #d9d9d9;
}

.bar_ratio div {
	height: 10px;
	background-color: var(--m-main-color);
}

.magazineNav {
	margin: 15px 0;
}

.mnh {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
}

.category-box {
	display: inline-block;
	/* border: 1px solid #a2a2a2;
    border-radius: 20px;
    padding: 4px 16px;
    color: #a2a2a2; */
	font-weight: 500;
	font-size: 14px;
	letter-spacing: -1px;
	margin-right: 7px;
	padding: 8px 12px;
	box-sizing: border-box;
	border: 1px solid rgb(232, 233, 235);
	border-radius: 8px;
	color: rgb(79, 85, 88);
	background-color: rgb(255, 255, 255);
	transition:
		background-color 300ms ease 0s,
		color 300ms ease 0s,
		border-color 300ms ease 0s;
}

.category-box.active {
	/* color: var(--main-color); */
	color: #fff;
	border: 1px solid var(--main-color);

	background-color: var(--main-color);
}
/* 
.bbtiList{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
} */
.bbtiList {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
	gap: 5px;
	margin-bottom: 20px;
}

.bbti_checkbox {
	display: none;
}

.bbti_label {
	text-align: center;
	display: inline-block;
	border: 1px solid var(--m-main-color);
	border-radius: 8px;
	cursor: pointer;
	font-size: 14px;
	color: var(--m-main-color);
	line-height: 32px;
	width: 100px;
	height: 33px;
}

.bbti_checkbox:checked + label {
	background-color: var(--m-main-color);
	color: #fff;
}

.editImg {
	width: 150px;
	max-width: 150px;
}

/* [hm] 토글 디자인 추가 : 2023.09.19 */
/* 슬라이더 기본 스타일 */
.toggle .toggle-slider {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	background-color: #ff4789;
	border-radius: 15px;
	transition: background-color 0.3s;
	cursor: pointer;
}

/* 슬라이더 스위치 */
.toggle-slider:before {
	position: absolute;
	content: "";
	height: 16px;
	width: 16px;
	left: 2px;
	bottom: 2px;
	background-color: white;
	border-radius: 50%;
	transition: transform 0.3s;
}

/* 토글 활성 상태 스타일 */
input:checked + .toggle-slider {
	background-color: #ff4789;
}

input:checked + .toggle-slider:before {
	transform: translateX(20px);
}

/* 클릭 불가능한(disabled) 스타일 */
.toggle-slider.disabled {
	background-color: #ccc;
	opacity: 0.5;
}

.pink_btn {
	border-radius: 4px;
	border: 1px solid var(--main-color);
	background-color: #fff;
	padding: 10px;
	color: var(--main-color) !important;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
}
.pink_btn:hover {
	background-color: var(--main-color);
	color: #fff !important;
}

/* 회원가입 */
.signIn-form .input_box input {
	width: 100%;
	padding-left: 20px;
	/* background: #ffffff; */
	height: 44px;
	box-sizing: border-box;
	border: 1px solid #ddd;
	transition: 1s;
	margin-bottom: 20px;
}
.signIn-form .input_box input[type="checkbox"] {
	width: 14px;
	height: 15px;
	padding: 0;
}
.signIn-form .input_box input::placeholder {
	font-size: 13px;
	color: #333;
}

.signIn-form .input_box input:focus {
	border: 1px solid #000;
}

.signIn-form .input_box input[type="file"] {
	margin: 10px 0 20px 0;
}
.signIn-form select {
	border: 1px solid #ddd;
	height: 44px;
	width: 100%;
	margin-bottom: 20px;
	padding: 0 10px;
}

.signup-content {
	margin-top: 2rem;
}
#admin-signup-menu {
	display: flex;
	gap: 5px;
}

#admin-signup-menu a {
	flex: 1;
	margin-top: 20px;
	margin-bottom: 20px;
}

.brand-checkbox-input {
	display: none;
}

.brand-checkbox-label {
	display: inline-block;
	padding: 4px 15px;
	border: 1px solid var(--m-main-color);
	color: var(--m-main-color);
	border-radius: 5px;
	margin-right: 10px;
	margin-bottom: 10px;
	cursor: pointer;
	margin-top: 10px;
	transition: background-color 0.3s;
}

.brand-checkbox-input:checked + .brand-checkbox-label {
	background-color: var(--m-main-color);
	color: #fff;
}

.mr-1 {
	margin-right: 8px;
}

/* Skeleton style */
.skeleton {
	margin: 10px 0;
	border-radius: 4px;
	background-color: #e0e0e0;
	background-image: linear-gradient(90deg, #e0e0e0, #f8f8f8, #e0e0e0);
	background-size: 200% 100%;
	animation: skeleton-loading 1.5s infinite linear;
}

@keyframes skeleton-loading {
	from {
		background-position: 200% 0;
	}
	to {
		background-position: -200% 0;
	}
}

.errorWrap {
	display: flex;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	place-content: stretch center;
	height: 500px;
	max-height: 500px;
}
.errorWrap .h404 {
	/* margin-top: 40px; */
	text-align: center;
	color: rgb(0, 0, 0);
	font-weight: bold;
	font-size: 17px;
	line-height: 30px;
}
.errorWrap p {
	text-align: center;
	color: rgb(122, 127, 134);
	margin-bottom: 16px;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: -0.5px;
}
.e-home-btn {
	background: #000;
	color: #fff !important;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 10px;
}
.signUpBtn {
	font-size: 14px;
	font-weight: bold;
	margin-top: 0.5rem;
	text-align: right;
}

.brand-logo-imgbox {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}
.brand-logo-imgbox img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: opacity 300ms ease 0s;
}

.tagsNav {
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	margin: 10px 0;
}
.tag-box {
	font-size: 12px;
	gap: 1px;
	height: 36px;
	white-space: nowrap;
	padding: 6px 9px 6px 6px;
	border: 1px solid rgb(232, 233, 235);
	border-radius: 100px;
	color: rgb(79, 85, 88);
	background-color: rgb(255, 255, 255);
	transition:
		background-color 300ms ease 0s,
		color 300ms ease 0s,
		border-color 300ms ease 0s;
	cursor: pointer;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}
.bars{
	color : var(--main-color);
}
.tag-box.active {
	color: #fff;
	background-color: var(--main-color);
	border: 1px solid var(--main-color);
}
/* [hm] bbti 테스트 css */
.bg-grey {
	background-color: #c4c4c4;
}
.border-blue {
	border: solid 1px #0d6efd;
}
.question-box {
	width: 100%;
	max-width: 420px;
	margin: 24px auto 72px auto;
	padding: 60px 16px 24px 16px;
	border-radius: 8px;
	box-shadow: 0 4px 25px #00000000;
	background-color: #ffffff;
}
.question-progress-bar {
	width: 90%;
	margin: 8px auto;
	height: 8px;
}
.progress-bg {
	background-color: #e4e4e4;
	border-radius: 8px;
}
.progress-target {
	width: 0%;
	height: 8px;
	background-color: #000000;
	border-radius: 8px;
	transition-duration: 0.5s;
}
.question-footer {
	/* position: fixed; */
	width: 100%;
	margin: 36px auto 0 auto;
}
.question-box .share {
	border: solid 1px #fff;
	color: #fff;
}
.question-box .share:hover {
	transition: all 0.3s;
	border: solid 1px #0d6efd;
	color: #0d6efd;
}
.result-box {
	width: 90%;
	max-width: 420px;
	margin: 24px auto 72px auto;
	padding: 24px 40px 24px 40px;
	border-radius: 8px;
	box-shadow: 0 4px 25px #00000000;
	background-color: #ffffff;
}
/* Skeleton.css */
/* Skeleton style */
.skeleton {
	margin: 10px 0;
	border-radius: 4px;
	background-color: #e0e0e0;
	background-image: linear-gradient(90deg, #e0e0e0, #f8f8f8, #e0e0e0);
	background-size: 200% 100%;
	animation: skeleton-loading 1.5s infinite linear;
}

@keyframes skeleton-loading {
	from {
		background-position: 200% 0;
	}
	to {
		background-position: -200% 0;
	}
}

.errorWrap {
	display: flex;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	place-content: stretch center;
	height: 500px;
	max-height: 500px;
}
.errorWrap .h404 {
	/* margin-top: 40px; */
	text-align: center;
	color: rgb(0, 0, 0);
	font-weight: bold;
	font-size: 17px;
	line-height: 30px;
}
.errorWrap p {
	text-align: center;
	color: rgb(122, 127, 134);
	margin-bottom: 16px;
	font-size: 15px;
	font-weight: 500;
	letter-spacing: -0.5px;
}
.e-home-btn {
	background: #000;
	color: #fff !important;
	font-weight: 500;
	padding: 10px 20px;
	border-radius: 10px;
}
.signUpBtn {
	font-size: 14px;
	font-weight: bold;
	margin-top: 0.5rem;
	text-align: right;
}

.m-category-item span {
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	font-weight: 500;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	letter-spacing: -0.5px;
}
.m-category-icon{
	width: 24px;
	height: 24px;
	fill: #ff9680;
	object-fit: contain;
}

.brand-logo-imgbox {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}
.brand-logo-imgbox img {
	border-radius: 50%;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: opacity 300ms ease 0s;
}

.brandNav {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	gap: 3px;
	margin: 10px 0;
	overflow-x: scroll;
}
.brandNav::-webkit-scrollbar {
	display: none;
}
.brand-box {
	font-size: 12px;
	gap: 1px;
	height: 36px;
	white-space: nowrap;
	padding: 6px 9px 6px 6px;
	border: 1px solid rgb(232, 233, 235);
	border-radius: 100px;
	color: rgb(79, 85, 88);
	background-color: rgb(255, 255, 255);
	transition:
		background-color 300ms ease 0s,
		color 300ms ease 0s,
		border-color 300ms ease 0s;
	cursor: pointer;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}
.brand-box.active {
	color: #fff;
	background-color: var(--main-color);
	border: 1px solid var(--main-color);
}
/* [hm] bbti 테스트 css */
.bg-grey {
	background-color: #c4c4c4;
}
.border-blue {
	border: solid 1px #0d6efd;
}
.question-box {
	width: 100%;
	max-width: 420px;
	margin: 24px auto 72px auto;
	padding: 60px 16px 24px 16px;
	border-radius: 8px;
	box-shadow: 0 4px 25px #00000000;
	background-color: #ffffff;
}
.question-progress-bar {
	width: 90%;
	margin: 8px auto;
	height: 8px;
}
.progress-bg {
	background-color: #e4e4e4;
	border-radius: 8px;
}
.progress-target {
	width: 0%;
	height: 8px;
	background-color: #000000;
	border-radius: 8px;
	transition-duration: 0.5s;
}
.question-footer {
	/* position: fixed; */
	width: 100%;
	margin: 36px auto 0 auto;
}
.question-box .share {
	border: solid 1px #fff;
	color: #fff;
}
.question-box .share:hover {
	transition: all 0.3s;
	border: solid 1px #0d6efd;
	color: #0d6efd;
}
.result-box {
	width: 90%;
	max-width: 420px;
	margin: 24px auto 72px auto;
	padding: 24px 40px 24px 40px;
	border-radius: 8px;
	box-shadow: 0 4px 25px #00000000;
	background-color: #ffffff;
}

/* Post.js css */
.prodcut-content {
	position: relative;
	width: 100%;
	max-width: 600px;
}
.post-wrap {
	position: relative;
}
.post-wrap .post-content {
	display: inline-block;
	/* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1); */
	padding: 15px 0;
	width: 100%;
}
.post-tags {
	display: flex;
	gap: 1px;
	/* margin-bottom: 8px; */
}
.post-details-info .post-tags {
	margin-top: 8px;
	/* margin-bottom: 18px; */
	/* border-bottom:  1px solid rgb(234, 235, 239); */
	padding: 10px 0;
}
.post-wrap .post-content .post-tit {
	font-weight: 400;
	font-size: 17px;
	letter-spacing: -0.5px;
}
.post-wrap .post-content .post-desc {
	letter-spacing: -0.5px;
	font-weight: 400;
	font-size: 15px;
	color : #7D7C8A;
}

.post-wrap .post-content .post-info {
	display: flex;
	justify-content: space-between;
	color: #a2a2a2;
	font-size: 14px;
	margin-top: 0.8rem;
}
.post-details-info {

}
.post-user-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
}
.post-details-info .post-user {
	display: flex;
	align-items: center;
	/* margin-top: 10px; */
}
.post-details-info .post-user button {
	margin-left: auto;
	/* color :#979797; */
	color: #bfbfbf;
	font-size: 17px;
}
.post-details-info .post-user img {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	margin-right: 0.5rem;
}
.post-details-info .post-user span {
	font-weight: 500;
}
.post-details-info .post-date span {
	color: #a2a2a2;
	font-size: 13px;
	font-weight: 500;
	letter-spacing: -0.5px;
}
.post-details-info .post-date .date:after {
	content: "|";
	display: inline-block;
	font-size: 12px;
	color: #a2a2a2;
	margin-right: 5px;
	padding-left: 7px;
	transform: translateY(-1px);
}
.post-content {
	padding: 20px 0;
	border-bottom: 1px solid rgb(234, 235, 239);
}
.post-content span {
	font-weight: 400;
}

.likeBtn {
	border: 1px solid #ddd;
	padding: 5px 34px;
	border-radius: 50px;
}
.likeBtn.wished {
	color : var(--main-color);
	border: 1px solid var(--main-color); /* isWished가 true일 때 테두리색 변경 */
}

.post-tag {
	display: inline-block;
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -1px;
	margin-right: 12px;
	/* padding: 4px 10px; */
	box-sizing: border-box;
	/* border: 1px solid rgb(232, 233, 235); */
	border-radius: 4px;
	/* color: rgb(79, 85, 88); */
	color: var(--main-color);
	/* background-color: #f7f3e4; */
	background-color: rgb(255, 255, 255);
}
.post-tag.hot {
	color: red;
}
.post-tag.new {
	color: var(--m-main-color);
}
.post-wrap .post-content .post-info .heart {
	margin-right: 0.7rem;
	color : var(--main-color);
}
.post-wrap .post-content .post-info .comment{
	color : var(--main-color);
}
.post-wrap .post-content .post-info .date:after {
	content: "|";
	display: inline-block;
	font-size: 12px;
	color: #a2a2a2;
	margin-right: 5px;
	padding-left: 7px;
	transform: translateY(-1px);
}
.posting-btn {
	position: fixed;
	z-index: 9999;
	bottom: 80px;
	border-radius: 50%;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	cursor: pointer;
	visibility: visible;
	transition: visibility 0.4s ease 0s;
	background: var(--m-main-color);
	box-shadow:
		inset 0 0 0 1px hsla(0, 0%, 100%, 0.2),
		0 4px 6px rgba(0, 0, 0, 0.1),
		0 8px 30px rgba(0, 0, 0, 0.15);
	-webkit-backdrop-filter: blur(30px);
	backdrop-filter: blur(30px);
	will-change: transform, opacity;
}
.posting-btn .pen {
	color: #fff;
}

/* PostWrite.js */
.postWrite-field {
}
.postWrite-field.title input {
	display: block;
	width: 100%;
	box-sizing: border-box;
	margin: 0px;
	padding: 15px 50px 15px 0px;
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid rgb(234, 235, 239);
	color: rgb(41, 41, 41);
	font-size: 16px;
	font-weight: bold;
	line-height: 22px;
}

.postWrite-field.content textarea {
	width: 100%;
	height: 40vh;
	box-sizing: border-box;
	min-height: 152px;
	padding: 16px;
	overflow: scroll;
	display: inline-block;
	overflow-wrap: break-word;
	white-space: pre-line;
	font-size: 14px;
	line-height: 20px;
	z-index: 1;
	border-radius: 10px;
	color: rgb(41, 41, 41);
	border: 1px solid rgb(234, 235, 239);
	resize: none;
}

.more-wrap {
	background-color: #ffff;
	border-radius: 10px 10px 0 0;
	box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.05);
	position: absolute;
	bottom: 0;
	z-index: 9999999;
	/* top: 100%; */
	overflow: hidden;
	transition: all 600ms cubic-bezier(0.86, 0, 0.07, 1);
	position: absolute;
	left: 0;
	width: 100%;
	height: 20%;
	opacity: 0;
}

.more-wrap.active {
	bottom: 10px;
	opacity: 1;
}
.more-wrap button {
	width: 100%;
	padding: 10px;
	border: 1px solid;
	border-radius: 10px;
	margin-bottom: 1rem;
	color: blue;
}
.more-wrap .complaint {
	color: red;
}
.post-comment {
}
.post-comment .comment-wrap {
	display: flex;
	gap: 10px;
	margin-top: 15px;
}

.post-comment .comment-wrap .comment-user img {
	width: 30px;
	height: 30px;
	border-radius: 100%;
}

.post-comment .comment-wrap .comment-content {
	width: 100%;
}

.post-comment .comment-wrap .comment-content .comment-info {
	display: flex;
	justify-content: space-between;
}
.post-comment .comment-wrap .comment-content .comment-info .nickname {
	font-weight: 500;
	margin-right: 10px;
}
.post-comment .comment-wrap .comment-content .comment-info .date {
	color: #a2a2a2;
	font-size: 12px;
}

.tagList {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

/* Mypage.js css */
.menu-box {
	display: flex;
	justify-content: space-between;
	background-color: #f5f5f5;
	padding: 20px;
	width: 100%;
	border-radius: 10px;
	margin-bottom: 1rem;
}
.menu-box a {
	text-align: center;
}
.menu-box p {
	font-weight: 500;
	letter-spacing: -0.8px;
	font-size: 14px;
}
.menu-box span {
	color: #a2a2a2;
}

.my-info {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	margin-top: 1rem;
	gap: 10px;
}
.my-info p {
	font-weight: 500;
	letter-spacing: -0.9px;
}
.my-info .profile {
	width: 50px;
	height: 50px;
	/* margin-bottom: 1rem; */
}
.my-info .profile img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}
.settingBtn {
	margin-left: auto;
}

.select-tags {
	font-weight: bold;
	letter-spacing: -0.9px;
	font-size: 14px;
	width: 16%;
}

/* 댓글 등록 */
.reply-wrap {
	border-top: 1px solid #eeeeee;
	/* border: 1px solid #000; */
	max-width: 600px;
	margin: auto;
	height: 65px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #fff;
	z-index: 99;
	border-radius: 16px 16px 0px 0px;
	border-top: 0.5px solid #d2d5d6;
	border-left: 0.5px solid #d2d5d6;
	border-right: 0.5px solid #d2d5d6;
}

.reply-wrap form {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.reply-wrap form input {
	width: 100%;
	height: 100%;
	border: none;
	/* border-radius: 16px 16px 0px 0px; */
	padding-left: 12px;
}
.reply-wrap form button {
	/* width: 10%; */
	padding: 0 12px;
	font-size: 14px;
	color: #333;
	border-left: 1px solid #d2d5d6;
}
.reply-wrap .right-box {
	width: 172px;
}

#offcanvasBottom {
	position: absolute;
	height: 100px;
	width: 100%;
	bottom: 0;
	border-radius: 16px 16px 0px 0px;
}
.offcanvas-body {
	padding-top: 25px;
}
.more-button {
	cursor: pointer;
}
.more-button span {
	font-size: 15px;
	font-weight: bold;
	letter-spacing: -0.9px;
}

.reportTit {
	font-weight: bold;
	letter-spacing: -0.9px;
}
.reportBtn {
	width: 100%;
	background: var(--main-color);
	border-radius: 5px;
	padding: 10px 0px;
	color: #fff;
	font-weight: 500;
	letter-spacing: -0.9px;
}
.post-title {
	font-weight: 500;
	letter-spacing: -0.98px;
	font-size: 18px;
}

.menuWrap {
	margin-top: 2rem;
}
.menuWrap .menutitle {
	border-bottom: 1px solid;
	padding-bottom: 10px;
}

.menuWrap .menutitle p {
	font-weight: 500;
	letter-spacing: -0.9px;
	font-size: 16px;
}
.menuWrap .menubox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 10px;
	border-bottom: 1px solid #eee;
}
.menuWrap .menubox p {
	font-weight: 400;
	letter-spacing: -0.9px;
	font-size: 14px;
}

.profile-edit {
	margin-bottom: 30px;
}
.profile-edit p {
	font-weight: 500;
	font-size: 14px;
	letter-spacing: -0.9px;
}

.mpreimg {
	width: 100px;
	height: 100px;
	position: relative;
	margin-bottom: 3rem;
}
.mpreimg img {
	width: inherit;
	height: inherit;
	object-fit: cover;
}
.profile-edit .profileImg button {
	position: absolute;
	bottom: 0;
	right: 0;
	color: #fff;
	background-color: var(--main-color);
	padding: 5px;
	cursor: pointer;
	padding: 5px;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.profile-edit input[type="text"] {
	width: 100%;
	padding-left: 20px;
	/* background: #ffffff; */
	height: 44px;
	box-sizing: border-box;
	border: 1px solid #ddd;
	transition: 1s;
	border-radius: 4px;
}

.w80nick {
	display: flex;
	align-items: center;
	gap: 5px;
}
.w80nick input[type="text"] {
	width: 80%;
}
.w80nick button {
	width: 20%;
}

.signin-content {
	/* margin-top: 4rem; */
}
.login-header {
	text-align: center;
	margin-bottom: 2rem;
}
.loginLogo-wrap {
	margin-top: 100px;
	margin-bottom: 20px;
	text-align: center;
}
.loginLogo-wrap img {
	width: 149px;
	height: 59px;
}
.login-header p {
	font-weight: 500;
	font-size: 15px;
	letter-spacing: -0.7px;
	margin: 0px;
	color: #999999;
}
.login-field {
}
.login-field input {
	border-bottom: 1px solid #979797;
	border-top: none;
	border-left: none;
	border-right: none;
	padding: 10px 20px;
	width: 100%;
	margin: 5px 0;
}

.btn-login {
	background-color: var(--main-color);
}

.btn-kakao-login {
	background-color: #fee500;
	color: #131100;
	font-weight: 500;
	position: relative;
}
.btn-kakao-login img {
	width: 26px;
	position: absolute;
	left: 26px;
}
.join-member {
	text-align: center;
	margin: 15px 0;
}
.join-member span {
	font-weight: 500;
	font-size: 14px;
	letter-spacing: -0.9px;
	margin-right: 0.5rem;
}
.join-member a {
	text-decoration: underline;
	font-weight: bold;
	letter-spacing: -0.9px;
	font-size: 15px;
}

/* 고객 회원가입 */

.join-field {
	margin-bottom: 2rem;
}
.join-form .input_box input {
	width: 100%;
	/* padding-left: 20px; */
	/* background: #ffffff; */
	border-top: none;
	border-left: none;
	border-right: none;
	box-sizing: border-box;
	border-bottom: 1px solid #ddd;
	transition: 1s;

	margin-top: 1rem;
}

.join-form .input_box p {
	font-weight: 500;
	letter-spacing: -0.9px;
	font-size: 14px;
}
.join-form .require {
	margin: 20px 0;
	text-align: center;
}
.join-form .require p {
	font-size: 15px;
	font-weight: 500;
	letter-spacing: -0.9px;
}
.join-form .input_box input[type="checkbox"] {
	width: 14px;
	height: 15px;
	padding: 0;
}
.join-form .input_box input::placeholder {
	font-size: 13px;
	color: #333;
}

.join-form .input_box input:focus {
	border-bottom: 1px solid #000;
}

.join-form .input_box input[type="file"] {
	margin: 10px 0 20px 0;
}
.join-form select {
	border: 1px solid #ddd;
	height: 44px;
	width: 100%;
	margin-bottom: 20px;
	padding: 0 10px;
}

.join-form .icheck_box {
	display: flex;
	align-items: end;
	gap: 5px;
	margin-bottom: 2rem;
}
.join-form .icheck_box div {
	flex: 3;
}
.join-form .icheck_box button {
	flex: 1;
	border: 1px solid;
	padding: 10px 0;
}

.agree-term {
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;
}
.checkboxA {
	display: block;
	position: absolute;
	left: -9999px;
}
.checkboxA + label {
	display: inline-block;
	position: relative;
	padding: 0 0 0 29px;
	font-size: 14px;
	line-height: 24px;
	vertical-align: top;
}
.checkboxA + label {
	font-weight: 500;
	font-size: 15px;
}
.checkboxA.s + label {
	font-size: 14px;
}
.checkboxA + label::before {
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	margin: 0 5px 0 -29px;
	border: 1px solid #e0e0e0;
	border-radius: 50%;
	background: #fff url("/public/img/ico_check_g.png") no-repeat center / 12px
		auto;
	vertical-align: top;
}

.checkboxA:checked + label::before {
	border-color: var(--main-color);
	background-color: var(--main-color);
	background-image: url("/public/img/ico_check_g.png");
}

.checkTermArea li {
	display: flex;
	justify-content: space-between;
}
.checkTermArea li a {
	font-size: 12px;
	font-weight: 500;
}
.editpbtn {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99;
	max-width: 600px;
	margin: auto;
}
.mpr-item {
	border-bottom: 1px solid #eee;
	padding: 8px 12px;
	width: 100%;
}
.mpr-item .tit {
	font-size: 15px;
	color: #000000;
	margin: 0 5px;
}
.mpr-item div {
	display: flex;
	gap: 5px;
	justify-content: space-between;
}
.mpr-item span {
	color: #999;
	font-size: 13px;
}
.mpr-item div button {
    width: 90px;
    height: 35px;
    background-color: var(--main-color);
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
}

#profile_file {
	/* display: none; */
}
.profileImg {
	display: flex;
	justify-content: center;
}
.hidden-file-input {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	border: 0;
	clip: rect(0, 0, 0, 0);
}
.selectFileBtn {
	position: absolute;
	/* border: 1px solid #e0e0e0; */
	padding: 21px;
	border-radius: 50%;
	bottom: -22px;
	right: -18px;
	cursor: pointer;
}
.editProfile_img-wrap {
	width: 100px;
	height: 100px;
	position: relative;
	margin-bottom: 3rem;
}
.editProfile_img-wrap img {
	width: inherit;
	height: inherit;
	object-fit: cover;
	border-radius: 100%;
}

.fileLabel {
	display: inline-block;
	padding: 10px 20px;
	background-color: #007bff;
	color: white;
	border-radius: 5px;
	cursor: pointer;
}
.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.filter-wrap {
	display: flex;
	/* justify-content: space-between; 2024.07.22 : CHM : 조회기준 추가 시 space-between으로 변경 예정*/
	justify-content: end;
	margin: 10px 0px;
}
.filter-wrap .filter-title {
	font-size: 13px;
	font-weight: bold;
}
.offcanvas.offcanvas-bottom {
	max-width: 600px;
	width: 100%;
	height: 32vh !important;
	background: #ffffff;
	margin: 0 auto;
	border-radius: 10px 10px 0 0;
}
.offcanvas-title {
	font-size: 16px !important;
	font-weight: bold !important;
	line-height: 26px !important;
	color: rgb(32, 36, 41) !important;
}
.offcanvas-header .btn-close {
	font-size: 14px !important;
	font-weight: bold !important;
}
.bbtiList_service {
	display: flex;
	gap: 5px;
	margin-bottom: 3rem;
	flex-wrap: wrap;
}
.bbti_label_s {
	display: inline-block;
	width: 100%;
	height: 32px;
	border: 1px solid #e9e9e9;
	padding: 0 9px;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	outline: 0;
	cursor: pointer;
	font-size: 12px;
	line-height: 30px;
	color: #1b1b1b;
	word-break: break-all;
	word-wrap: break-word;
	background: #fff;
}
.apply-btn {
	background-color: var(--main-color);
}
.bbtiFilterBtn-wrap {
	display: flex;
	gap: 10px;
}
.bbtiFilterBtn-wrap button {
	width: 50%;
}
.reset-btn {
	background: #d2d2d2;
	color: #000000;
}
.product-wrap .moreBtn {
	margin: 20px auto 48px;
	width: 200%;
	background: #ffffff;
	border: 1px solid #e9e9e9;
	text-align: center;
	border-radius: 5px;
	display: block;
	line-height: 44px;
	color: #1a1a1a;
	font-size: 14px;
	font-weight: 400;
}
/* 2024.06.13 : CHM : 매거진 이미지 css */
.post-image-block {
	position: relative;
	width: 136px;
	height: 136px;
	padding: 8px;
	transition: 0.4s ease-in-out;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 2px 0px;
	overflow: hidden;
	cursor: pointer;
}
.post-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/* 2024.08.13 : CHM :분석탭 프로그레스바 */
.research-progress{
	width: 100%;
	display: flex;
	align-items: center;
}
.research-progress-bar{
	display: flex;
	gap: 2px;
	width: 100%;
	height: 8px;
}
.research-progress-bar .research-step{
	background-color: #ffccc2;
	width: 32%;
}
.research-progress-bar .research-step.success{
	background-color: #e85f43;
}
.research-progress-bar .research-step:nth-child(1){
	border-radius: 8px 0 0 8px;
}
.research-progress-bar .research-step:nth-child(3){
	border-radius: 0 8px 8px 0;
}

/* 2024.06.10 : CHM : 분석탭 테스트카드 css */
.test-card {
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	padding: 16px 24px;
	margin: 12px 0px;
	background: white;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 2px 0px;
	cursor: pointer;
}
.test-card.active {
	background: #ffccc2;
}
.test-card .img-block {
	width: 10%;
	text-align: center;
}
.test-card .img-block .test-card-img {
	width: 28px;
	height: 28px;
}
.test-card .text-block {
	width: 70%;
	position: relative;
	text-align: center;
}
.test-card .text-block .test-card-heading {
	font-weight: bold;
	font-size: 12px;
}
.test-card .text-block .test-card-state {
	font-size: 10px;
	color: #666666;
	padding-top: 4px;
}
.test-card .text-block .test-card-state .active {
	font-size: 10px;
	color: #ff6847;
	padding-top: 4px;
}

/* 스크롤바 표시 x */
.none-scroll {
	-ms-overflow-style: none; /* 인터넷 익스플로러 */
	scrollbar-width: none; /* 파이어폭스 */
}
.none-scroll:-webkit-scrollbar {
	display: none;
}

/* 상품 라벨링 체크박스(사이즈) 커스텀 */
/* AddLabelModal.css */
.custom-checkbox-group {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	font-size: 12px;
	font-weight: bold;
	color: var(--main-color);
}
.custom-checkbox-group .form-check {
	padding-left: 0px;
}
.custom-checkbox .form-check-input {
	display: none;
}
.custom-checkbox .form-check-label {
	display: inline-block;
	width: 48px;
	height: 24px;
	border: 1px solid var(--main-color);
	border-radius: 8px;
	cursor: pointer;
	text-align: center;
	line-height: 20px;
	margin-right: 8px;
}
.custom-checkbox.custom-checkbox-long .form-check-label {
	width: 96px;
	border-radius: 4px;
}
.custom-checkbox.custom-checkbox-circle .form-check-label {
	width: 24px;
	height: 24px;
	border-radius: 50%;
}
.custom-checkbox .form-check-input:checked + .form-check-label {
	background-color: var(--main-color);
	color: #ffffff;
}
.custom-checkbox .form-check-input + .form-check-label {
	display: flex;
	align-items: center;
	justify-content: center;
}
.SelectTag-wrap {
	display: flex;
	gap: 14px;
	align-items: center;
	border-bottom: 1px solid rgb(234, 235, 239);
	padding: 10px 0;
}
.SelectTag-wrap p {
	font-weight: bold;
	letter-spacing: -0.9px;
	font-size: 14px;
}

.aiTags-list {
	display: flex;
	gap: 10px;
}

.aitag {
	padding: 5px 11px;
	border: 1px solid var(--main-color);
	color: var(--main-color);
	cursor: pointer;
	border-radius: 6px;
	font-size: 13px;
}
.aitag.active {
	background-color: var(--main-color);
	color: #ffffff;
}
.SelectTag-wrap {
	display: flex;
	gap: 7px;
	align-items: center;
	border-bottom: 1px solid rgb(234, 235, 239);
	padding-bottom: 15px;
}
.SelectTag-wrap .SelectTags {
}

.SelectTags-input {
	width: calc(100% - 10% - 10px);
	padding: 10px;
	border: none;
}
.post-Tags-wrap {
	display: flex;
	gap: 3px;
}

.TagInputBox {
	width: 100%;
}
.TagInputBox input {
	width: 100%;
	border: none;
	transition: color 0.3s ease;
}
.TagInputBox input::placeholder {
	color: #888;
	font-size: 14px;
	letter-spacing: -0.9px;
}
.TagInputBox input:focus::placeholder {
	color: transparent;
}

.input-post-tag {
	display: inline-flex; 
	justify-content: start;
	align-items: center;
	position: relative;
	font-weight: bold;
	font-size: 12px;
	letter-spacing: -1px;
	margin-right: 3px;
	padding:10px 20px 10px 14px;
	box-sizing: border-box;
	border: 1px solid rgb(232, 233, 235);
	border-radius: 8px;
	color: rgb(79, 85, 88);
	background-color: rgb(255, 255, 255);
	width: auto;
	white-space: nowrap; 
    flex-wrap: nowrap; 
    min-width: 0; 
}
.input-post-tag {
	font-size: 14px;
}

.input-post-tag .tag-close {
	position: absolute;
	right: 9px;
	cursor: pointer;
}
.findId input[type="text"],
.findId input[type="password"] {
	width: 100%;
	max-width: none !important;
	height: 44px;
	padding-left: 12px;
	border: 1px solid #e9e9e9;
	border-radius: 5px;
	margin: 0 0 24px;
}
.findId p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	margin: 0 0 8px !important;
	padding: 0;
}

.find-info {
	margin: 16px 0 0;
	font-size: 14px;
	line-height: 24px;
	height: auto;
	text-align: center;
}
.find-info a {
	position: relative;
	display: inline-block;
	padding: 0 10px 0 10px;
	color: #8a8a8a;
	font-weight: normal;
}

.find-info .pbtn:before {
	display: block;
	content: "";
	position: absolute;
	top: 6px;
	left: 0;
	width: 1px;
	height: 13px;
	background: #848484;
}
#sort-dropdown {
	padding: 0;
	font-weight: bold;
	font-size: 14px;
	color: #000000;
	background: #fff;
	border: none;
	width: 158px;
	text-align: right;
}

#sort-dropdown::after {
	display: none;
}
.sort-box {
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
}
.sort-box p{
	font-weight: 500;
	font-size: 15px;
	color: #000000;
}
.sort-box span{
	font-weight: bold;
	font-size: 15px;
	color: var(--main-color);
}
.dropdown-menu {
	border-radius: 0 !important;
	border: 1px solid #000;
}
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
	background-color: transparent !important;
	color: inherit;
	outline: none;
	box-shadow: none;
}
.dropdown-item:active {
	font-weight: bold;
}
.tags-slider {
	margin-top: 4rem;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}

.post-img-filebox {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin: 20px 0 40px 0;
}

.post-img-filebox label,
.post-img-filebox img {
	display: inline-block;
	width: 100px;
	height: 100px;
	color: gray;
	vertical-align: middle;
	background-color: #ffffff;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	border: 1px solid #e9e9e9;
	cursor: pointer;
	font-weight: bold;
	font-size: 15px;
}

.post-img-filebox input[type="file"] {
	position: absolute;
	width: 0;
	height: 0;
	padding: 0;
	overflow: hidden;
	border: 0;
}

.post-image-container {
	position: relative;
	display: inline-block;
}

.post-image-container img {
	width: 100px;
	height: 100px;
	border-radius: 10px;
	border: 1px solid #e9e9e9;
}

.post-image-container button {
	position: absolute;
	top: 5px;
	right: 5px;
	background: #000000;
	color: white;
	border: none;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	cursor: pointer;
	font-size: 12px;
	line-height: 20px;
	text-align: center;
}
.pcm {
	color: var(--main-color);
}

.fbutton-wrap {
	display: flex;
	gap: 10px;
}
.find_contents {
	height: 50vh;
	/* height: 20vh; */
	flex-direction: row;
	align-content: center;
	justify-content: center;
}

/* 관리자페이지 커스텀 툴팁 */
.admin-tooltip-container {
	position: relative;
	cursor: pointer;
	transition: all 0.2s;
}
.admin-tooltip {
	--background-tooltip: #6e7681;
	position: absolute;
	top: -39px; /* Adjusted top position */
	left: 50%;
	transform: translateX(-50%);
	padding: 0.5em;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s;
	background: var(--background-tooltip);
	color: white; /* Text color */
	border-radius: 5px;
	display: inline-block;
	height: 30px;
	font-size: 13px;
	text-align: center;
}
.admin-tooltip::before {
	position: absolute;
	content: "";
	height: 0.6em;
	width: 0.6em;
	bottom: -0.2em;
	left: 50%;
	transform: translate(-50%) rotate(45deg);
	background: var(
		--background-tooltip
	); /* Use the same background color as the tooltip */
}
.admin-tooltip-container:hover .admin-tooltip {
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
}
.admin-tooltip-item {
	padding: 0px 2px;
}

/* 체형분석 페이지 안내 */
.research-card {
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	height: auto;
	min-height: 48px;
}
.research-card-carousel {
	position: absolute;
	animation: carousel 20s linear var(--carousel_direction, normal) infinite;
}
.research-card-carousel[data-direction="right"] {
	--carousel_direction: reverse;
}
.research-card-text {
	font-size: 0.75rem;
	color: black;
	text-wrap: nowrap;
}
@keyframes carousel {
	to {
		transform: translate(-50%);
	}
}

/* 메인페이지 소곤톡 콘텐츠 미리보기 */
.main-post-block {
	width: 100%;
	/* padding: 15px 10px; */
	padding: 15px 0px;
	color: #a2a2a2;
	font-size: 12px;
	/* border: 1px solid rgb(228 229 229); */
	border-bottom: 1px solid rgb(228 229 229);
	animation-delay: 0s;
	/* border-radius: 8px; */
	margin-bottom: 10px;
}
.main-post-block .main-post-content {
	display: flex;
	justify-content: space-between;
}
.main-post-block .main-post-content .post-title {
	font-size: 16px;
	color: rgb(62, 65, 69);
	font-weight: 500;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.main-post-block .main-post-content .post-time {
	min-width: 48px;
	text-align: right;
}
.main-post-block .main-post-tag {
	display: flex;
	gap: 5px;
}
/* 소곤톡 애니메이션 효과 추가 */
@keyframes postFadeInOut {
	0%,
	90% {
		opacity: 0;
		transform: translateY(-8px);
		color: #a2a2a2;
	}
	22%,
	80% {
		opacity: 1;
		transform: translateY(0px);
		color: var(--main-color);
	}
}
.main-post .post-type {
	opacity: 0;
	font-size: 14px;
	color: var(--main-color);
	margin-left: 4px;
	display: inline-block;
	animation: postFadeInOut 10s infinite;
}
@keyframes postBlockFadeInOut {
	0% {
		opacity: 0;
		transform: translateY(-2px);
	}
	20%,
	80% {
		opacity: 1;
		transform: translateY(4px);
	}
	90% {
		opacity: 0;
		transform: translateY(0px);
	}
}
.main-post-block {
	opacity: 0;
	animation: postBlockFadeInOut 10s infinite;
}

.aiTags-list {
	display: flex;
	gap: 10px;
}

.comment-container {
	word-break: break-word;
	white-space: pre-wrap;
}
.comment-container span {
	font-weight: 400;
}
.findId-radio {
	display: flex;
	gap: 10px;
	margin-bottom: 50px;
}

.findId-radio label {
	flex: 1;
	text-align: center;
}

.fpd {
	display: flex;
	gap: 10px;
}
.fpd input {
	flex: 4;
	margin: 0 auto !important;
	height: 51px !important;
}
.getCodeBtn {
	flex: 1;
	border: 1px solid var(--main-color);
	color: var(--main-color);
	padding: 5px;
	font-size: 15px;
	border-radius: 5px;
}

.fm-wrap p {
	color: var(--main-color);
}
.auth-method p {
}
.findId-radio input[type="radio"] {
	display: none;
}

.radio-button {
	display: inline-block;
	padding: 10px 20px;
	border: 1px solid transparent;
	border-bottom: 2px solid transparent;
	cursor: pointer;
	margin-right: 10px;
	transition:
		border-bottom-color 0.2s,
		color 0.2s;
}

.radio-button:hover {
	color: var(--main-color);
}

input[type="radio"]:checked + .radio-button {
	border-bottom-color: var(--main-color);
	color: var(--main-color);
}

.aphidden-check-box {
	display: flex;
	align-items: center;
	gap: 5px;
	margin-bottom: 20px;
}
.aphidden-check-box label {
	font-size: 14px;
	font-weight: 500;
	letter-spacing: -0.9px;
}

.magazine_d_footer {
	display: flex;
	gap: 12px;
	justify-content: end;
	align-items: center;
	padding: 20px 10px;
	/* border-top: 1px solid #eee; */
	/* border-bottom: 1px solid #eee; */
}
.mlike-box {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: center;
	margin-top: 46px;
	gap: 10px;
}
.mlike-box p {
	font-weight: 400;
}
.magazine_d_footer button {
	font-size: 18px;
	color: #1b1b1b;
}
/* 체형검사결과페이지 스피너 */
.spin-body {
	--uib-size: 35px;
	--uib-speed: 0.8s;
	--uib-color: #ff843d;
	position: relative;
	display: inline-block;
	height: var(--uib-size);
	width: var(--uib-size);
	animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}
.spin-body__dot {
	position: absolute;
	height: 100%;
	width: 30%;
}
.spin-body__dot:after {
	content: "";
	position: absolute;
	height: 0%;
	width: 100%;
	padding-bottom: 100%;
	background-color: var(--uib-color);
	border-radius: 50%;
}
.spin-body__dot:nth-child(1) {
	bottom: 5%;
	left: 0;
	transform: rotate(60deg);
	transform-origin: 50% 85%;
}
.spin-body__dot:nth-child(1)::after {
	bottom: 0;
	left: 0;
	animation: wobble1 var(--uib-speed) infinite ease-in-out;
	animation-delay: calc(var(--uib-speed) * -0.3);
}
.spin-body__dot:nth-child(2) {
	bottom: 5%;
	right: 0;
	transform: rotate(-60deg);
	transform-origin: 50% 85%;
}
.spin-body__dot:nth-child(2)::after {
	bottom: 0;
	left: 0;
	animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
		ease-in-out;
}
.spin-body__dot:nth-child(3) {
	bottom: -5%;
	left: 0;
	transform: translateX(116.666%);
}
.spin-body__dot:nth-child(3)::after {
	top: 0;
	left: 0;
	animation: wobble2 var(--uib-speed) infinite ease-in-out;
}
@keyframes spin78236 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes wobble1 {
	0%,
	100% {
		transform: translateY(0%) scale(1);
		opacity: 1;
	}
	50% {
		transform: translateY(-66%) scale(0.65);
		opacity: 0.8;
	}
}
@keyframes wobble2 {
	0%,
	100% {
		transform: translateY(0%) scale(1);
		opacity: 1;
	}
	50% {
		transform: translateY(66%) scale(0.65);
		opacity: 0.8;
	}
}
/* 체형검사결과페이지 스피너 종료 */

.my-magazine-content {
	display: flex;
	gap: 20px;
	padding: 10px 0;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
}
.my-m-img {
	width: 100px;
	height: 100px;
}
.my-m-img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
}
.m-mheart {
	color: var(--main-color);
	font-size: 14px;
	margin-top: 10px;
	font-weight: 400;
}
.swal2-popup {
	padding: 20px;
}
#swal2-html-container {
	font-size: 16px !important;
	font-weight: 600 !important;
	letter-spacing: -0.3px !important;
}
.swal2-title {
	font-size: 16px !important;
	font-weight: 600 !important;
}
.swal2-popup .swal2-styled {
	background-color: var(--main-color) !important;
	padding: 5px 25px !important;
	font-weight: 500 !important;
	font-size: 15px !important;
}

.orderingList{
	display: flex;
	flex-direction: column;
}
.orderingList li{
	cursor: pointer;
	font-size: 15px;
	padding: 12px 0;
	border-top: 1px solid rgb(245, 246, 248);
	font-weight: 500;
    letter-spacing: -0.6px;
}
.category-s-wrap{
	display: flex;
	gap : 8px;
	margin-bottom: 15px;
}

.sogontalk{
	margin-top : 20px;
}
.sogontalk .title{
	display: flex;
	justify-content:space-between;
	align-items: center;
	gap: 10px;
	margin-bottom: 12px;
}
.sogontalk .title p{
    font-size: 20px;
    letter-spacing: -0.8px;
    font-weight: 600;
}
.sogontalk .contents{
    display: flex;
    flex-direction: column;
}

.sogontalk .contents .post-item{
	cursor: pointer;
    display: flex;
    justify-content: space-between;
	border-bottom: 1px solid rgb(228 229 229);
	padding: 12px 0;
}

.sogontalk .contents .post-item .post-tit{

}
.sogontalk .contents .post-item .post-tit p{
	font-size: 16px;
	font-weight: 400;
	letter-spacing: -0.5px;
	line-height : 26px;
}
.sogontalk .contents .post-item .post-tit .post-stats{
	display: flex;
	gap:10px;	
}
.sogontalk .contents .post-item .post-tit .post-stats p:nth-child(1){
	color : var(--main-color);
	font-size: 14px;
	font-weight: 400;
}
.sogontalk .contents .post-item .post-tit .post-stats p:nth-child(2){
	color : #a0a0a0;
}
.main-meta-icon{
	width: 18px;
	height: 18px;
	fill: #ff843d;
}
.like-icon {
	width: 18px;
	height: 18px;
	fill: #ccc; 
	cursor: pointer;
    margin-right: 8px;
}
.like-icon.liked {
	fill: #ff843d;
}

.post-meta {
	display: flex;
	gap:10px;	
}
.post-meta div{
	display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: center;
}
.post-meta div span{
	color : var(--main-color);
	font-size: 15px;
}

.tab-btns{
	display: flex;
	gap:8px;
}

.tab-btn{
	cursor: pointer;
	border : 1px solid var(--main-color);
	display: inline-block;
	padding: 3px 14px;
    border-radius: 21px;
	color : var(--main-color);	
}
.tab-btn.select{
	background-color: var(--main-color);
	color : #ffffff;
}
.posticon{
	width: 18px;
	height: 18px;
	margin-right: 3px;
	fill: #ff843d;
}

.mypage-wrap{
	padding: 20px;
	padding-bottom: 80px;
}
.mypage-box{
	background: #ffffff;
    border-radius: 13px;
    padding: 15px 20px;
	margin-bottom: 12px;
}
.mp-tit{
	padding: 12px 0;
    border-bottom: 1px solid #ECECEC;
    margin-bottom: 6px;
}
.mp-tit p{
	font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.5px;
}
.mypage-box li{
	padding: 9px 0;
	color : #565656;
	font-weight: 500;
}
.user-info-wrap{
	background-color: #E85F44;
	height: 180px;
	position: relative;
}

.user-info-wrap .curve-line{
	position: absolute;
    bottom: -37px;
    width: 230px;
    left: -33px;
}

.user-info-wrap .curve-line2{
	position: absolute;
    top: -56px;
    right: -12px;
    width: 216px;
}
.user-info-box{
	position: absolute;
    bottom: 22px;
    left: 30px;
	z-index: 999;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.user-info-box h3{
	color : #ffffff;
	letter-spacing: -0.4px;
	font-size: 27px;
    font-weight: 700;
	cursor: pointer;
}
.my-logout{
	margin: 18px 0;
	width: 100%;
	background: #FECCC2;
	padding: 16px;
	border-radius: 26px;
	color : var(--main-color);
	font-weight: 700;
}
.mypage-mht{
	font-size: 23px;
	font-weight: 600;
	letter-spacing: -0.8px;
}

.ms-menu-wrap li
{
	border-bottom: 1px solid #ECECEC;
    padding: 22px 10px;
    font-size: 15px;
    letter-spacing: -0.4px;
    font-weight: 600;
}
.ms-menu-wrap li p{
	font-size: 15px;
    letter-spacing: -0.4px;
    font-weight: 600;
}

.post-title-wrap{
	display: flex;
	gap:10px;
    align-items: center;
}
.post-title-wrap .cls-1{
	fill: #959595;
}

/* 체형분석 소개 페이지 */
.intro-thumb-wrap {
	width: 100%;
    text-align: center;
    background-color: #fff1e0;
    position: relative;
    overflow: hidden;
    height: 400px;
}
.intro-thumb-wrap .text-area{
	padding: 150px 0;
	font-weight: 800;
    position: relative;
    z-index: 2;
}
.floating-svg-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
}
.floating-svg {
    position: absolute;
    width: 360px; /* svg 크기 */
    animation: float 10s linear infinite;
}
.floating-svg:nth-child(1) {
    position: absolute;
    width: 360px; /* svg 크기 */
    animation: float2 10s linear infinite;
}
.floating-svg:nth-child(2),
.floating-svg:nth-child(4){
    position: absolute;
    width: 80px; /* svg 크기 */
    animation: float3 10s linear infinite;
}
@keyframes float {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    25% {
        transform: translate(100px, -150px) rotate(90deg);
    }
    50% {
        transform: translate(-160px, 150px) rotate(180deg);
    }
    75% {
        transform: translate(160px, -50px) rotate(270deg);
    }
    100% {
        transform: translate(0, 0) rotate(360deg);
    }
}
@keyframes float2 {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    30% {
        transform: translate(60px, 40px) rotate(-90deg);
    }
    70% {
        transform: translate(120px, -50px) rotate(-270deg);
    }
    100% {
        transform: translate(0, 0) rotate(-360deg);
    }
}
@keyframes float3 {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    30% {
        transform: translate(-100px, -250px) rotate(-90deg);
    }
    70% {
        transform: translate(-250px, -50px) rotate(-270deg);
    }
    100% {
        transform: translate(0, 0) rotate(-360deg);
    }
}
.type-test-btn{
	color: #e85f43;
	border: 1px solid #e85f43;
	margin: 0 auto;
	text-align: center;
	border-radius: 50px;
	width: 100%;
	padding: 12px;
	font-weight: 700;
}
.type-test-btn.active{
	background-color: #e85f43;
	color: #fff;
}


/* SimpleTestQuestion 체형분석 질문 */
.stq-wrap {
	position: relative;
	left: 0;
	padding: 72px 16px;
}
.stq-wrap .progress-box{
	width: 100%;
	height: 8px;
	border-radius: 50px;
	background-color: #ffccc2;
	position: relative;
}
.stq-wrap .progress-bar{
	height: 8px;
	border-radius: 50px;
	background-color: #e85f43;
}
.stq-wrap .stq-question{
	margin: 24px auto;
	text-align: center;
}
.stq-wrap .stq-question .title{
	font-size: 18px;
	font-weight: 600;
}
.stq-wrap .stq-question .answer-area{
}
.stq-wrap .stq-question .answer-area .select-type{
	margin-top: 40px !important;
	font-size: 12px;
	color: #666666;
}
.stq-wrap .stq-question .answer-area .answer-btn{
	/* 버튼형 */
	width: 100%;
	border: 1px solid #aaaaaa;
	border-radius: 50px;
	padding: 16px;
	font-weight: 600;
	margin-top: 12px;
	transition: all 0.5s ease-out;
}
.stq-wrap .stq-question .answer-area .answer-btn.active{
	background-color: #ffccc2;
	color: #e85f43;
	border: 1px solid #ffccc2;
}
.stq-wrap .stq-question .answer-area .block-type{
	margin: 12px 0px;
	display: flex;
	gap: 8px;
	justify-content: space-evenly;
	flex-wrap: nowrap;
}
.stq-wrap .stq-question .answer-area .block-type .answer-block{
	/* 블록형 */
	width: 100%;
	border: 1px solid #aaaaaa;
	border-radius: 8px;
	padding: 12px;
	font-weight: 600;
	transition: all 0.5s ease-out;
}
.stq-wrap .stq-question .answer-area .block-type .answer-block p{
	font-size: 12px;
	font-weight: 600;
}
.stq-wrap .stq-question .answer-area .block-type .answer-block img{
	max-width: 100%;
}
.stq-wrap .stq-question .answer-area .block-type .answer-block.active{
	border: 1px solid #e85f43;
	background-color: #ffccc2;
}

.stq-bottom{
	position: fixed;
	display: flex;
	gap: 8px;
	bottom: 0;
	width: 100%;
	max-width: 600px;
	background-color: #fff;
	padding: 16px 16px 24px 16px;
	box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.1);
}
.stq-bottom .btn{
	width: 100%;
	border: 1.5px solid #ff9680;
	color: #ff9680;
	border-radius: 50px;
	font-size: 14px;
	font-weight: 600;
	padding: 12px;
	transition: all 0.5s ease-out;
}
.stq-bottom .btn.disable{
	border: 1.5px solid #aaaaaa;
	color: #aaaaaa;
}
.stq-bottom .btn.active{
	background-color: #e85f43;
	color: #fff;
	border: none;
}

.log-wrap{
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.log-wrap .log-item{
	display: flex;
	gap : 13px;
}

.log-wrap .log-item .log-img{
	width: 150px;
	height: 100px;
}
.log-img img {
	border-radius: 10px;
	width: 100%;
	height: 100%;
	max-height: 190px;
	object-fit: cover;
}
.log-content{
	width: calc(100% - 150px);
}
.log-content2{
	width: calc(100% - 150px);
	display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.log-title{
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.5px;
}

.log-desc{
	font-size: 14px;
	font-weight: 400;
	color: #666666;
}
.log-btns{
	margin-top: 20px;
	display: flex;
    justify-content: space-between;
}
.log-category{
	display: inline-block;
	color : #ffffff;
	font-size: 14px;
	padding: 3px 11px;
	letter-spacing: -0.5px;
	border-radius: 20px;
	background-color: var(--main-color);
}

.log-category2{
	color : var(--main-color);
	font-size: 14px;
}
.log-stats{
	display: flex;
	gap: 15px;
	color : var(--main-color)
}
.log-stats div{
	display: flex;
	gap: 2px;
	align-items: center;
}
.log-stats span{
	font-size: 14px;
	font-weight: 500;
}

.first-log .thumbnail{
	width: 100%;
    height: 340px;
    overflow: hidden;
    position: relative;
}

.first-log .thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
	border-radius: 10px
}

.first-log .log-tit{
	
}
.first-log .log-tit p{
	position: absolute;
	bottom: 63px;
	left: 30px;

	font-size: 21px;
	font-weight: bold;
	letter-spacing: -0.5px;
	line-height: 25px;

}
.first-log .log-tit span{
	position: absolute;
	bottom: 22px;
	left: 30px;
}
.slide {
	background-color: #f5f5f5; /* 배경색 */
	padding: 20px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
  
.slick-slide {
	text-align: center;
	transition: transform 0.5s ease;
}

.slick-center .slick-slide {
	transform: scale(1.1); /* 중앙 슬라이드를 조금 더 크게 보이게 설정 */
}

.sogonlog-title-wrap{
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.sogonlog-title-wrap p{
	color : var(--main-color);
	font-size: 18px;
	font-weight: 500;
}

.sogonlog-nav{
	border-top : 1px solid var(--main-color);
	border-bottom : 1px solid var(--main-color);
	padding: 10px 0;
}
.nav-item{
	display: flex;
	align-items: center;
	gap: 6px					
}
.line-top{
	border-top : 1px solid #ECECEC;
}
